class Downloader {
    download(content, type, name) {
        let blob = new Blob( [ content ], { type: type } );

        let link = document.createElement( 'a' );
        link.href = window.URL.createObjectURL( blob );
        link.download = name;
        link.click();
    }
}

export default new Downloader();
