<template>
    <div>
        <div class="card">
            <div class="card-header">
                <button @click="palletBarcodes(locations.length)" class="btn" :disabled="!locations.length"><i class="fas fa-print mr-2"></i>Подготовить ШК для паллет ({{locations.length}} шт)</button>
                <button
                    v-if="showDeleteStockItemsButton"
                    :disabled="deleteStockItemsButtonDisabled()"
                    class="btn btn-danger pull-right"
                    @click="deleteStockItems"
                >
                    <i class="fas fa-trash mr-2"></i>Удалить из ячейки
                </button>
            </div>
            <div class="card-body">
                <div v-if="loading" class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i></div>
                <template v-else>
                    <div v-if="locations.length === 0" class="alert alert-info text-center">Нет заполненных ячеек</div>
                    <div v-for="location in locations" v-bind:key="location.id">
                        <div class="mb-2">
                            <h4>Ячейка <b>{{location.name}}</b></h4>
                            <span class="pull-right">Вместимость: {{location.capacity}} ящиков. Размещено: {{location.stockItems.length}} ящиков</span>
                        </div>
                        <div class="table-responsive pl-5">
                            <table class="table table-bordered table-sm">
                                <thead>
                                <tr>
                                    <th class="checked-column">
                                        <b-checkbox class="checked" v-model="location.checked" @change="onLocationCheckedChange($event, location)"/>
                                    </th>
                                    <th>Ящик</th>
                                    <th>Номенклатура</th>
                                    <th class="text-right">Кол-во</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="location.stockItems.length === 0">
                                    <td colspan="3" class="text-center">ячейка пуста</td>
                                </tr>
                                <tr v-for="item in location.stockItems" v-bind:key="item.id">
                                    <td class="checked-column">
                                        <b-checkbox class="checked" v-model="item.checked" @change="onStockItemCheckedChange($event, location, item)"/>
                                    </td>
                                    <td>{{item.entity.box.barcode.code}}</td>
                                    <td>{{item.entity.nomenclature.name}}</td>
                                    <td class="text-right">{{item.entity.count}}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td class="text-right" colspan="3">Итого:</td>
                                    <td class="text-right">{{sumBy(location.stockItems, i => parseInt(i.entity.count))}}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <hr>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {STOCK_ENDPOINT} from "@utils/endpoints";
    import {sumBy} from 'lodash';
    import Downloader from "@utils/Downloader";

    export default {
        name: "PalletCells",
        data() {
            return {
                locations: [],
                loading: false,
            };
        },
        methods: {
            sumBy,
            load() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.get(STOCK_ENDPOINT + '/not_empty_locations', {
                    params: {
                        without_loading: true,

                        location_type: 'zone',
                        with: [
                            'stockItems.entity.box.barcode',
                            'stockItems.entity.nomenclature.measureUnit'
                        ]
                    }
                }).then(response => {
                    this.locations = response.data
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.loading = false;
                });
            },
            palletBarcodes(count) {
                this.$http.get(STOCK_ENDPOINT + `/pallet/barcodes/${count}`, {
                    responseType: 'arraybuffer',
                    params: {
                        without_loading: true,
                    }
                }).then(response => {
                    Downloader.download(response.data, 'application/pdf', 'шк.pdf');
                }).catch(response => {
                    this.$toast.error(response.data.message);
                });
            },
            onLocationCheckedChange(checked, location) {
                location.checked = checked
                if (checked) {
                    for (let index in location.stockItems) {
                        location.stockItems[index].checked = true
                    }
                } else {
                    if (location.stockItems.filter(item => item.checked).length === location.stockItems.length) {
                        for (let index in location.stockItems) {
                            location.stockItems[index].checked = false
                        }
                    }
                }
                this.$forceUpdate()
            },
            onStockItemCheckedChange(checked, location, item) {
                item.checked = checked
                if (location.stockItems.filter(item => item.checked).length === location.stockItems.length) {
                    location.checked = true
                } else {
                    location.checked = false
                }
                this.$forceUpdate()
            },
            showDeleteStockItemsButton() {
                return this.$auth
                    .user()
                    .roles
                    .some(role => [ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR].includes(role))
            },
            deleteStockItemsButtonDisabled() {
                return !this.locations.some(location => location.stockItems.some(item => item.checked))
            },
            deleteStockItems() {
                const ids = this.locations.flatMap(
                    location => location.stockItems.filter(item => item.checked).map(item => item.id)
                )
                this.$http.post(STOCK_ENDPOINT + '/rollback', {
                    stock_item_ids: ids
                }).then(response => {
                    this.load()
                }).catch(response => {
                    if (!!response.data && !!response.data.message ) {
                        this.$toast.error(response.data.message);
                    }
                })
            }
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>
    .checked-column {
        padding-left: 8px;
        width: 14px !important;
    }
    .checked {
        margin: 0;
        padding-top: 2px;
    }
    th, td {
        vertical-align: middle !important;
    }
</style>
